import { A, useLocation, useNavigate } from '@solidjs/router';
import BgSplashBanner from '~/assets/backgrounds/tempSplashBanner.png';
import { FIND_YOUR_PERFECT_PLACE, HOME, SUBMIT_A_RENTAL_APPLICATION, TENANT_SIGN_IN } from '~/assets/strings';
import { LocalizationButton } from '~/components/layouts/Navigation/localization/LocalizationButton';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { CreateRentalApplicationUseCase } from '~/use-cases/rental-applications/createRentalApplicationUseCase';

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const active = (path: string) => (path == location.pathname ? 'border-sky-600' : 'border-transparent hover:border-sky-600');
  const { t } = useLocalization();

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: createRentalApplication, didSucceed } = useUseCase(CreateRentalApplicationUseCase);

  const onCreateRentalApplication = async () => {
    await createRentalApplication();
    if (didSucceed()) {
      navigate(`${presentableRentalApplication()?.url}/applicant-information`);
    }
  };

  const navigateToTenantSignIn = () => {
    window.location.href = `/tenants/auth/signin`;
  };

  return (
    <>
      <nav class="fixed z-20 flex w-full justify-between bg-[#0D0D0D]/20 p-3 py-5">
        <ul class="container flex items-center text-gray-200">
          <li class={`border-b-2 ${active('/')} mx-1.5 sm:mx-6`}>
            <A href="/">{t(HOME)}</A>
          </li>
        </ul>
        <div class="flex w-full justify-end align-middle">
          <button>
            <div class={`mx-1.5 border-b-2 border-sky-600 text-gray-200 sm:mx-6`} onClick={onCreateRentalApplication}>
              {t(SUBMIT_A_RENTAL_APPLICATION)}
            </div>
          </button>
          <div class="my-1">
            <LocalizationButton />
          </div>
        </div>
      </nav>
      <main class="mx-auto flex h-screen w-full flex-col items-center justify-center text-center">
        <div
          class="relative flex size-full flex-col items-center justify-center gap-6 bg-navy bg-cover bg-left bg-no-repeat text-white"
          style={{ 'background-image': `url(${BgSplashBanner})` }}>
          <div class="absolute inset-0 bg-black/20" />
          <div class="relative text-5xl font-semibold">{t(FIND_YOUR_PERFECT_PLACE)}.</div>
          <button onClick={navigateToTenantSignIn} class="z-10 flex w-full justify-center ">
            <div class=" mt-6 flex w-fit items-center rounded-full border bg-white px-4 py-2 text-primary-color">{t(TENANT_SIGN_IN)}</div>
          </button>
        </div>
      </main>
    </>
  );
}
